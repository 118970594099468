import { Component, ViewChild,ElementRef, OnInit, TemplateRef} from '@angular/core';
import { MacraComponent } from '../macra/macra.component';
import { CaseinfoComponent } from '../caseinfo/caseinfo.component';
import { CaretoolComponent } from '../caretool/caretool.component';
import { attribute, patientDemographics ,provider, document } from 'src/app/_models/case-details';
import { CaseRecResponse } from 'src/app/_models/case-rec-response';
import { CaseDetails } from 'src/app/_models/case-details';
import { MulesoftAPIServiceService } from 'src/app/_services/mulesoft/mulesoft-apiservice.service';
import { fromEvent,timer } from 'rxjs';
import { catchError, debounceTime, take } from "rxjs/operators";
import { AbstractControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";  
import { Globalconstants, enumcaseStatus,enumTabs, caseStatusByenumKey, enumMode } from 'src/app/helper/globalconstants';
import { LogService } from 'src/app/_services/log/log.service';
import { CaseRecStatus } from 'src/app/_models/case-rec-status';
import { formatDate} from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Logdetails } from 'src/app/_models/logdetails';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { resourceLimits } from 'worker_threads';
import { Console } from 'console';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
  

})


export class TabsComponent implements OnInit{

  
  public isMobile= false;  
  public statusCaseRec: string;
  createdBy:string;
  btnClose:boolean = false;
  public arrAttribute: attribute[];
  public arrIndicatorsAttribute: attribute[];
  public FinalarrAttribute: attribute[];
  public CaseRecInfo : CaseRecResponse ; 
  public CaseDtl : CaseDetails;
  public PatientInfo : patientDemographics[];
  public Doc: document[];
  public provider: provider[];
  public IsSuccess : boolean =  false;
  public IsSuccessCaretool : boolean =  false;
  public isSuccessCaseinfo:boolean = false;
  public FieldID : string;
  @ViewChild(MacraComponent) childMacra:MacraComponent;
  @ViewChild(CaseinfoComponent) childCaseInfo:CaseinfoComponent;
  @ViewChild(CaretoolComponent) childCaretool:CaretoolComponent;
  public isErrorAlertAPICall: boolean=false;
  public ErrorMessageAPICall:string ="";
  public SuccessMessage: string;
  public isSuccessAlert: boolean=false;
  public ErrorMessage: string;
  public isErrorAlert: boolean=false;
  public Attributeval : string;
  public AttributeAdditionalNote : string;
  public AttributeID : string;
  public loading: boolean;
  public InfoMessage: string;
  public isInfoAlert: boolean=false;
  public isDisabled: boolean=false;
  public isBtnDisabled: boolean=false;
  public AdverseEventValue: string;
  public ActiveTab: string;
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  public caserecStatus: CaseRecStatus;
  public CaseRecID:Number;
  public RequestorID:Number;
  public modalRef: BsModalRef;
  public isTabDisplay: boolean = false;
  public IsPatientDemographicLoaded: boolean = false;
  public IsFormValuesLoaded: boolean = false;
  public IsCaseDetailsByIDLoaded: boolean = false;
  public logDtl : Logdetails;

  ngOnInit(): void {

    try{
      if(environment.informationLogging)
      {
        //this.logger.log("ngOnInit()","tabs.component.ts - OnInit Started ", [sessionStorage.getItem('caserecid')], "");  
      }
    }
    catch(error) {
        console.log("error while calling logging api OnInit Started: ", error)
    }
        this.CaseRecID = +sessionStorage.getItem('caserecid');
        this.RequestorID = +sessionStorage.getItem('requestorid');
        
        if(this.CaseRecID > 0 && this.CaseRecID!= undefined)
        {
          this.isMobile = this.isMobileDevice();
          // Load patient demographic information from caserec
            this.apiService.getCaserecPatientDemographicDetails(this.CaseRecID.toString(),this.RequestorID.toString()).subscribe(
              result => { 
                      //console.log("CaseRec API: ", result) ;   
                      if(result != undefined) {
                          sessionStorage.removeItem('caseInfoObject');   
                          sessionStorage.setItem('caseInfoObject', JSON.stringify(result));
                          this.IsPatientDemographicLoaded = true;
                          sessionStorage.removeItem('loggedInUser');   
                          sessionStorage.setItem("loggedInUser", (result.providerFirstName + ' ' + result.providerLastName));
                          sessionStorage.removeItem('role');   
                          sessionStorage.setItem('role', result.role);
                          sessionStorage.removeItem('mode');   
                          sessionStorage.setItem('mode', result.mode);                          
                      }
                    },
                    error => {
                              this.logger.log("ngOnInit()","tabs.component.ts - getCaserecPatientDemographicDetails API Error ", [sessionStorage.getItem('caserecid')], error.message);  
                              console.error(error);
                              this.isErrorAlertAPICall = true; 
                              this.ErrorMessageAPICall = Globalconstants.ErrorMessageAPICall;},
              );
              // Load all MACRA and caretool form values from IQ Database.
              this.apiService.getFormValues().subscribe(
                    result => { 
                      if(result != undefined) {
                        sessionStorage.removeItem('formValuesObject');   
                        sessionStorage.setItem('formValuesObject', JSON.stringify(result));
                        this.IsFormValuesLoaded = true;
                    }
                  },
                  error => {
                            this.logger.log("ngOnInit()","tabs.component.ts - getFormValues API Error ", [sessionStorage.getItem('caserecid')], error.message);  
                            console.error(error); 
                            this.isErrorAlertAPICall = true; 
                            this.ErrorMessageAPICall = Globalconstants.ErrorMessageAPICall;},
              ); 
              //Load get case details by ID from IQ Database
              this.apiService.getCaseDetailsByID(sessionStorage.getItem('caserecid')).subscribe(
                    result => { 
                      if(result != undefined) {
                        sessionStorage.removeItem('caseDetailsByIDObject');   
                        sessionStorage.setItem('caseDetailsByIDObject', JSON.stringify(result));
                        this.IsCaseDetailsByIDLoaded = true;
                    }
                  },
                  error => {
                            this.logger.log("ngOnInit()","tabs.component.ts - getCaseDetailsByID API Error ", [sessionStorage.getItem('caserecid')], error.message);  
                            console.error(error); 
                            this.isErrorAlertAPICall = true; 
                            this.ErrorMessageAPICall = Globalconstants.ErrorMessageAPICall;},
              );  
        }
        try{
          if(environment.informationLogging)
          {
              //this.logger.log("ngOnInit()","tabs.component.ts - OnInit Ended ", [sessionStorage.getItem('caserecid')], ""); 
          }
        }      
        catch(error) {
            console.log("error while calling logging api OnInit ended: ", error)
        }
        
}

  constructor(private apiService: MulesoftAPIServiceService,private el: ElementRef, 
    private SpinnerService: NgxSpinnerService,private logger: LogService, 
    private modalService: BsModalService) {
      this.ActiveTab = enumTabs.macra;
      

  }
  selectedTab(tab){
    this.ActiveTab =  tab;
    if (tab ==='macra' )
    {
      if (this.childMacra.detailform.get('MAC700').value =='1')
      {
        if(document.getElementById("btnSave") != null)
      {
        document.getElementById("btnSave").innerText ='Sign & Continue';
      }
      }
      else
      {
        if(document.getElementById("btnSave") != null)
      {
        document.getElementById("btnSave").innerText ='Continue';
      }
    }
    }
    else if(tab ==='info')
    {
      if(document.getElementById("btnSave") != null)
      {
      document.getElementById("btnSave").innerText ='Continue';
    }
    }
    else{
      if(document.getElementById("btnSave") != null)
      {
        document.getElementById("btnSave").innerText ='Submit and Complete';
      }
    }
  }

  onClickMsg(strStatus:string)
  {
    if(strStatus=="S")
    {
      this.isSuccessAlert= false;
    }
    else if(strStatus=="I")
    {
      this.isInfoAlert= false;
    }
    else if(strStatus=="A")
    {
      this.isErrorAlert= false;
    }
  }
  
  onClick(strStatus:string): void {
    
try  
{     
  this.isErrorAlertAPICall = false;
  this.ErrorMessageAPICall = ""; 
   
    if(strStatus === Globalconstants.NextEvent){      
     //console.log(this.router.navigate);
    // document.getElementById('#tabs a[href="#macra"]').tabIndex = 3
    
    
    if(this.ActiveTab == enumTabs.macra){
        // Start Code add for sign & continue button
        this.IsSuccess = this.childMacra.onSubmit();
        if(!this.IsSuccess){
          this.ActiveMacraTab();
          this.scrollToFirstInvalidControl();
          return;
        }
        if(this.childMacra.IsFieldChanged === true || this.IsCaseDetailsByIDLoaded === false)
        {
            this.childMacra.changeDate();
            this.childMacra.IsFieldChanged = false;
        }
        
        this.CaretoolClick();
        // End
         this.ActiveCareToolTab();
        } else if(this.ActiveTab == enumTabs.info) {
           this.ActiveMacraTab();
        } 
    }else {
        //console.log(this.childMacra);
        sessionStorage.setItem('IsChanged','');
        if (this.childMacra) {  
          
          if (strStatus == Globalconstants.CompleteStatus)
          {

              this.IsSuccess = this.childMacra.onSubmit();
              this.IsSuccessCaretool = this.childCaretool.onSubmit();
              this.isSuccessCaseinfo = this.childCaseInfo.onSubmit();

              if(!this.isSuccessCaseinfo){
                 this.ActiveInfoTab();
                 this.scrollToFirstInvalidControl();
                  return;
              }
              if(!this.IsSuccess){
                this.ActiveMacraTab();
                this.scrollToFirstInvalidControl();
                return;
              }
              if(!this.IsSuccessCaretool){
               this.ActiveCareToolTab();
                this.scrollToFirstInvalidControl();
                return;
              }
              // console.log('Testing Here ' + this.IsSuccess);
              // console.log('Caretool Testing Here ' + this.IsSuccessCaretool);
              // console.log('Caseinfo testing ' + this.isSuccessCaseinfo)
            if((this.IsSuccess) && (this.IsSuccessCaretool) && (this.isSuccessCaseinfo))
            //if(this.IsSuccess) 
              {
                this.SaveData(enumcaseStatus.Complete);
                this.ClearMIPSMeasures();
              }
          }
          else
          {
            // check if case rec id exist if not 
            this.isSuccessCaseinfo = this.childCaseInfo.onSubmit();
            this.IsSuccessCaretool = this.childCaretool.onSave();
            if(!this.isSuccessCaseinfo) {
              this.ActiveInfoTab();
              this.scrollToFirstInvalidControl();
              return
            }

            if(!this.IsSuccessCaretool){
              this.ActiveCareToolTab();
              this.scrollToFirstInvalidControl();
              return;
            }

            if(this.isSuccessCaseinfo && this.IsSuccessCaretool) {
             // this.SpinnerService.show();  
              this.SaveData(enumcaseStatus.Incomplete);
              this.ClearMIPSMeasures();
             // this.SpinnerService.hide(); 
            } 
             
          }
        }
      }
      
    }  
    catch(error)  
    { 
      var arrayParams= [strStatus, sessionStorage.getItem('caserecid')];
        this.logger.log("OnClick()","Error raised in tabs.component.ts - OnClick ", arrayParams,  error.message);                           
      //this.isErrorAlertAPICall = true;
      //this.ErrorMessageAPICall = Globalconstants.ErrorMessageUI; 
    } 
} 
UpdateCaseRecStatus(caserecID: string, strStatus: string){
    
    try{
        //console.log(strStatus);
        var arrayParams= [caserecID, strStatus];
        if(environment.informationLogging)
          {
            //this.logger.log("UpdateCaseRecStatus()","Start execution of updateCaseRecStatus", arrayParams,  "Start execution of updateCaseRecStatus");                           
          }
        

        const objCaseRecStatus: CaseRecStatus = {
          caseStatus : strStatus
        };
        console.log('objCaseRecStatus' + objCaseRecStatus);
        this.caserecStatus = objCaseRecStatus;
        this.apiService.UpdateCaseRecStatus(objCaseRecStatus,caserecID).subscribe(
          statusResult => { 
            if (statusResult.statusCode == "0")
            {
              this.isSuccessAlert = true;
              if(strStatus == Globalconstants.InCompleteStatus)
              {
                   this.SuccessMessage = Globalconstants.SuccessMessage;
              }
              else{
                   this.SuccessMessage = Globalconstants.SubmitMessage;
              }
               console.log('CQF Status' + statusResult.status);
                setTimeout(() => {
                  this.preventAbuse = false;
                }, 200);
               return true;
            } else{
              //console.log(statusResult);
                this.isErrorAlert = true;                
                this.ErrorMessage = Globalconstants.StatusErrorMessage; //"Record failed to save!!";
                //scroll to top of screen

                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth"
                });
                var arrayParams= [caserecID, strStatus];
                //this.logger.log("UpdateCaseRecStatus()","Error raised in tabs.component.ts - UpdateCaseRecStatus else condition ", arrayParams,  "CaseRec Response with Status: " + statusResult.statusCode);                           
                return false;
            }
            

          },
          error => {this.logger.log("UpdateCaseRecStatus()","tabs.component.ts - UpdateCaseRecStatus Error ", [sessionStorage.getItem('caserecid')], "");  
          console.error(error); 
            this.isErrorAlertAPICall = true; 
            this.ErrorMessageAPICall = Globalconstants.ErrorMessageAPICall;},
          );
    }
    catch(error)  
    { 
      var arrayParams= [caserecID, strStatus];
        this.logger.log("UpdateCaseRecStatus()","Error raised in tabs.component.ts - UpdateCaseRecStatus ", arrayParams,  error.message);                           
    } 

    if(environment.informationLogging)
    {
      //this.logger.log("UpdateCaseRecStatus()","End execution of updateCaseRecStatus", arrayParams,  "End execution of updateCaseRecStatus");
    }
}
  //Activate Info tab
  ActiveInfoTab(){
      this.ActiveTab = enumTabs.info;
      document.getElementById("caretool-tab").className="nav-link";
      document.getElementById("macra-tab").className="nav-link";
      document.getElementById("info-tab").className="nav-link active";
      document.getElementById("caretool").className="tab-pane fade ";
      document.getElementById("macra").className ="tab-pane fade";
      document.getElementById("info").className="tab-pane fade show active";      
      document.getElementById("btnSave").innerText ='Continue';      
    
  }

  ActiveCareToolTab(){
      this.ActiveTab = enumTabs.caretool;
      document.getElementById("caretool-tab").className="nav-link active";
      document.getElementById("macra-tab").className="nav-link";
      document.getElementById("info-tab").className="nav-link";
      document.getElementById("caretool").className="tab-pane fade show active";
      document.getElementById("macra").className ="tab-pane fade ";
      document.getElementById("info").className="tab-pane fade";      
      document.getElementById("btnSave").innerText ='Submit and Complete';
      }

  ActiveMacraTab(){
      this.ActiveTab = enumTabs.macra;
      document.getElementById("caretool-tab").className="nav-link";
      document.getElementById("macra-tab").className="nav-link active";
      document.getElementById("info-tab").className="nav-link";
      document.getElementById("caretool").className="tab-pane fade";
      document.getElementById("macra").className ="tab-pane fade  show active";
      document.getElementById("info").className="tab-pane fade";
      if (this.childMacra.detailform.get('MAC700').value =='1')
      {
        document.getElementById("btnSave").innerText ='Sign & Continue';
      }
      else
      {
        document.getElementById("btnSave").innerText ='Continue';
      }
  }

  DisableAllTabs(){
    document.getElementById("caretool-tab").className="nav-link disabled";
    document.getElementById("macra-tab").className="nav-link disabled";
    document.getElementById("info-tab").className="nav-link disabled";
    document.getElementById("caretoolheader").className="nav-item disabled";
    document.getElementById("macraheader").className="nav-item disabled";
    document.getElementById("infoheader").className="nav-item disabled";
    document.getElementById("macra").className ="tab-pane fade disabled";
    document.getElementById("caretool").className ="tab-pane fade disabled";
    document.getElementById("info").className ="tab-pane fade disabled";
  }

  ClearMIPSMeasures()
  {
    if(this.childMacra.detailform.get('MAC700').value ==="0")
    {
     this.childMacra.detailform.get('MAC022B').reset();
    this.childMacra.detailform.get('MAC216').reset();
    this.childMacra.detailform.get('MAC217').reset();
    this.childMacra.detailform.get('MAC218').reset();
    this.childMacra.detailform.get('MAC219').reset();
    //this.childMacra.detailform.get('MAC201').reset();
    //this.childMacra.detailform.get('MAC202').reset();
    this.childMacra.detailform.get('MAC014').reset();
    this.childMacra.detailform.get('MAC015').reset();
    this.childMacra.detailform.get('MAC015A').reset();
    this.childMacra.detailform.get('MAC015B').reset();
    this.childMacra.detailform.get('MAC022').reset();
    //this.childMacra.detailform.get('MAC023').reset();
     this.childMacra.detailform.get('MAC022A').reset();
     this.childMacra.detailform.get('MAC022B').reset();
     this.childMacra.detailform.get('MAC022C').reset();
     this.childMacra.detailform.get('MAC032').reset();
     this.childMacra.detailform.get('MAC032A').reset();
     this.childMacra.detailform.get('MAC032B').reset();
    this.childMacra.detailform.get('MAC032C').reset();
    this.childMacra.detailform.get('MAC221').reset();
    //this.childMacra.detailform.get('MAC224').reset();
    this.childMacra.detailform.get('MAC222').reset();
    this.childMacra.detailform.get('MAC223').reset();
    this.childMacra.detailform.get('MAC232').reset();
    this.childMacra.detailform.get('MAC235').reset();
    this.childMacra.detailform.get('MAC237').reset();
    this.childMacra.detailform.get('MAC239').reset();
    this.childMacra.detailform.get('MAC238').reset();
    //this.childMacra.detailform.get('MAC033').reset();
    //this.childMacra.detailform.get('MAC034').reset();
    this.childMacra.detailform.get('MAC226').reset();
    this.childMacra.detailform.get('MAC227').reset();
    this.childMacra.detailform.get('MAC228').reset();
    this.childMacra.detailform.get('MAC231').reset();
    this.childMacra.detailform.get('MAC229').reset();
    }

  }

  public CaretoolClick()
  {
    if(document.getElementById("btnSave") !=null)
      {
        document.getElementById("btnSave").innerText ='Submit and Complete';
      }    
try  
{     
  this.isErrorAlertAPICall = false;
  this.ErrorMessageAPICall = ""; 
    //console.log('craetooltab called');   
    //var iASA =  JSON.parse(sessionStorage.getItem('ASAStatus'));                        
    //console.log('craetooltab called'); 
    if(this.childMacra.isASAChanged === true || this.childCaretool.caretoolform.get('CT000').value === '0')  
    {
    var iASA =  JSON.parse(sessionStorage.getItem('ASAStatus'));  
    //                      
    let iASAdata = iASA.find(ob => ob['groupAttributeSubItemId'] === this.childMacra.detailform.get('MAC702').value);                        
    if(iASAdata)
    { 
      //console.log(iASAdata);
      var ASACla =  JSON.parse(sessionStorage.getItem('ASAClasificationArrList'));      
      let ASAdata = ASACla.find(ob => ob['groupAttributeName'] === iASAdata.groupAttributeSubItem);                        
      //console.log(ASAdata);
      if(ASAdata)
      {                          
        this.childCaretool.caretoolform.get('drpASA').setValue(ASAdata.groupAttributeId);
        this.childCaretool.RiskdrpASA =  false;
        //this.childCaretool.caretoolform.get('drpASA').disable();
      }
    }
    else{
      // this.childCaretool.caretoolform.get('drpASA').reset();
      // this.childCaretool.caretoolform.get('drpASA').enable();
      // this.childCaretool.caretoolform.get('drpASA').setValue('');
    }


  }
    //Anesthesia Type
    if(this.childMacra.isAnesTypeChanged === true || this.childCaretool.caretoolform.get('CT000').value === '0')
    {
    var iAnesType =  JSON.parse(sessionStorage.getItem('AnesTypeDocList'));                        
    let iAnesTypedata = iAnesType.find(ob => ob['groupAttributeSubItemId'] === this.childMacra.detailform.get('MAC701').value);                        
    if(iAnesTypedata)
    { 
      //console.log(iASAdata);
      var ASACla =  JSON.parse(sessionStorage.getItem('AnesTypeArrList'));      
      let ASAdata = ASACla.find(ob => ob['groupAttributeName'] === iAnesTypedata.groupAttributeSubItem);                        
      //console.log(ASAdata);
      if(ASAdata)
      {                          
        this.childCaretool.caretoolform.get('drpAnesthesiaType').setValue(ASAdata.groupAttributeId);
        //this.childCaretool.caretoolform.get('drpAnesthesiaType').disable();
      }
    }
    else{
      // this.childCaretool.caretoolform.get('drpAnesthesiaType').reset();
      // this.childCaretool.caretoolform.get('drpAnesthesiaType').enable();
      // this.childCaretool.caretoolform.get('drpAnesthesiaType').setValue('');
    }
    
  }

    if(this.childMacra.isEmergencyChanged === true || this.childCaretool.caretoolform.get('CT000').value === '0')
    {
    // Emergency field set..
        
    if(this.childMacra.detailform.get('MAC703').value)
    {
      this.childCaretool.caretoolform.get('CT533').setValue(this.childMacra.detailform.get('MAC703').value);
      //this.childCaretool.caretoolform.get('CT533').disable();
    }
    else{
      this.childCaretool.caretoolform.get('CT533').reset();
      //this.childCaretool.caretoolform.get('CT533').enable();
    } 
  }

  if (sessionStorage.getItem('mode').toLowerCase()==enumMode.readonly)
  {
    this.isDisabled = true;                  
    // Disabled Macra & Caretool controls..
    this.childCaretool.IsCompletedCase(); 
    this.childMacra.IsCompletedCase();
  }
  }  
  catch(error)  
  { 
      var arrayParams= [sessionStorage.getItem('caserecid')];
      this.logger.log("CaretoolClick()","Error raised in tabs.component.ts - CaretoolClick ", arrayParams,  error.message); 
  } 


  }

  private getName(control: AbstractControl): string | null {
    let group = <FormGroup>control.parent;
    if (!group) {
      return null;
    }
    let name: string;
    Object.keys(group.controls).forEach(key => {
      let childControl = group.get(key);
      if (childControl !== control) {
        return;
      }
      name = key;
    });
    return name;
  }
  
  SaveData(strStatus:string)
  { 
          
      try  
      {     
        this.isErrorAlertAPICall = false;
        this.ErrorMessageAPICall = "";          
          this.Doc= [];  
        
          //console.log(this.childCaretool.DocumentList());
          this.Doc = this.childCaretool.DocumentList();
          //console.log(this.Doc.length);
          this.provider = [];
          const objProvider: provider = {
              providerFirstName:  this.childCaseInfo.detailform.get('ClinicianFirstName').value,
              providerLastName:   this.childCaseInfo.detailform.get('ClinicianLastName').value,
              providerMiddleName: '',
              providerId:         '',
              providerNPI:        '',
              //providerRole:       '',
              providerTitle:      '',
              isManualEntry:      '',
              //isPrimary:          '',
              email:              this.childCaseInfo.detailform.get('Email').value,
              phone:              this.childCaseInfo.detailform.get('Phone').value,
              serviceLine:        '',
              caserecProvider:  JSON.parse('true')
        };
        
          const objTmpPatinetDtl: patientDemographics = {
              patientFirstName: this.childCaseInfo.detailform.get('FirstName').value,
              patientLastName: this.childCaseInfo.detailform.get('LastName').value,
              patientMiddleInitial: this.childCaseInfo.detailform.get('MiddleName').value,
              patientDob : this.childCaseInfo.detailform.get('DOB').value,
              patientDos : this.childCaseInfo.detailform.get('DOS').value,
              gender:   this.childCaseInfo.detailform.get('Gender').value,
              patientAccountNumber:        '',
              mrn:  this.childCaseInfo.detailform.get('MRN').value,
              patientIdentificationNumber:  '',
              race:this.childCaseInfo.detailform.get('Race').value,
              postInductionCancellationFlag :  JSON.parse(this.childCaseInfo.detailform.get('hdnpostInductionCancellationFlag').value ),
              anesthesiaStartTime:this.childCaseInfo.detailform.get('hdnanesthesiaStartTime').value,
              anesthesiaEndTime:this.childCaseInfo.detailform.get('hdnanesthesiaEndTime').value
              //,
              //handOfCase : true //this.childCaseInfo.detailform.get('hdnhandofcase').value 
          };
          this.arrAttribute = this.childMacra.SubmitMacra();  

          for (const field in this.childCaretool.caretoolform.controls) { // 'field' is a string  
          this.AttributeAdditionalNote="";
          this.FieldID  = this.getName(this.childCaretool.caretoolform.controls[field]);          
                  if ((this.FieldID==="CT533") || (this.FieldID==="CT548") || (this.FieldID==="CT549") || (this.FieldID==="CT550") || (this.FieldID==="CT000") || (this.FieldID==="CT605") || (this.FieldID==="CT607") || (this.FieldID==="CT508") || (this.FieldID==="CT608") )
                  {
                    this.AttributeID = this.getName(this.childCaretool.caretoolform.controls[field]);
                    if (this.childCaretool.caretoolform.controls[field].value==true)
                    {
                      this.Attributeval = "1";
                    }
                    else{
                    //this.Attributeval =this.childCaretool.caretoolform.controls[field].value;
                          if(this.FieldID==="CT605")
                          {                            
                            this.Attributeval = formatDate(this.childCaretool.caretoolform.controls[field].value, 'MM/dd/yyyy', 'en_US');
                          }
                          else if(this.FieldID==="CT607")
                          {
                            this.Attributeval ="";
                            this.AttributeAdditionalNote = this.childCaretool.caretoolform.controls[field].value;
                          }
                          else if(this.FieldID==="CT508")
                          {
                            this.Attributeval ="";
                            this.AttributeAdditionalNote = this.childCaretool.caretoolform.controls[field].value;
                          }
                          else{
                              this.Attributeval = this.childCaretool.caretoolform.controls[field].value;
                              }
                    }
                  }
                  else
                  {
                    this.AttributeID = this.childCaretool.caretoolform.controls[field].value;
                    if(this.childCaretool.caretoolform.controls[field].value == true)
                    {              
                      this.Attributeval ="";
                    }
                    else{
                      this.Attributeval ="1";
                    }
                  }

                  if(this.Attributeval || this.AttributeAdditionalNote)
                  {
                  const objTmp: attribute = {              
                    attributeId: this.AttributeID,              
                    attributeValue: this.Attributeval.toString(),
                    attributeAdditionalNotes:this.AttributeAdditionalNote,
                    attributeType: "Caretool"   
                  };
                      if((objTmp.attributeId) && (objTmp.attributeValue || objTmp.attributeAdditionalNotes))
                      {  
                          if(objTmp.attributeId.toString().length<11)
                          {                                                      
                            this.arrAttribute.push(objTmp);  
                          }                   
                      }
                      if(objTmp.attributeId === 'CT000' && objTmp.attributeValue ==="0") 
                          { break;}  
                    } 

                  }
                 
              if (sessionStorage.getItem('loggedInUser'))
              {this.createdBy = sessionStorage.getItem('loggedInUser');}

              this.arrIndicatorsAttribute = this.childCaretool.getDataSave();
              if(this.arrIndicatorsAttribute)
              {
                  this.FinalarrAttribute = this.arrAttribute.concat(this.arrIndicatorsAttribute);
              }
              else
              {
                this.FinalarrAttribute = this.arrAttribute;
              }
           //console.log(this.childCaseInfo.detailform.get('hdngeMnemonic').value);
          this.CaseDtl = {
            //caseRecUniqueId:     '',
            createdBy:this.createdBy,
            facilityId:        this.childCaseInfo.detailform.get('hdnfacilityid').value,      
            status:            strStatus,
            serviceLine : this.childCaseInfo.detailform.get('Serviceline').value,
            facilityName : this.childCaseInfo.detailform.get('Facility').value,
            facilitySiteCode : this.childCaseInfo.detailform.get('hdnfacilitysitecode').value,
            // anesthesiaStartTime : this.childCaseInfo.detailform.get('hdnanesthesiastarttime').value,
            // anesthesiaEndTime : this.childCaseInfo.detailform.get('hdnanesthesiaendtime').value,
            //anesthesiaStartTime : '11:20',
            //anesthesiaEndTime : '12:30',
            attributes :this.FinalarrAttribute,
            patientDemographics : objTmpPatinetDtl,
            documents : this.childCaretool.DocumentList(),
            providers : this.childCaretool.ClinicianList(),
            gemnemonic : this.childCaseInfo.detailform.get('hdngeMnemonic').value,
            handOfCase : this.childCaseInfo.detailform.get('hdnhandofcase').value 
        }
      
       console.log('CaseDtl', this.CaseDtl);
        //Need to replace hard coded value with casrecid from caserec api
        if(strStatus === enumcaseStatus.Complete){
          this.statusCaseRec = caseStatusByenumKey.get(enumcaseStatus.Complete);
        }
        else
        {
          this.statusCaseRec = caseStatusByenumKey.get(enumcaseStatus.Incomplete);
        }

      const objCaseRecStatus: CaseRecStatus = {
            caseStatus : this.statusCaseRec
          };

        //console.log(this.CaseDtl);   

        //We need to remove the second call - For status update..
        //Also we can remove code related to informational log.

        // New code will comment & use existing code.
          
        // this.apiService.saveCaseDetails(this.CaseDtl, sessionStorage.getItem('caserecid')).
        // pipe(map(result => {          
        //   const status = result.status;    
        //   //console.log('Hand-ofcase-saveCaseDetails:' + result.status);      
        //   this.logger.log("1. saveCaseDetails()","Get Response from Mulesoft API for Completed/In-complete Status", [sessionStorage.getItem('caserecid').toString()],  "#saveCaseDetails() - Get Response from Mulesoft API for Completed/In-complete Status");                                      
        //   return status;               
        //   }),          
        //   mergeMap(status => this.apiService.UpdateCaseRecStatus(objCaseRecStatus ,sessionStorage.getItem('caserecid')))).subscribe(
        //     statusResult => { 
        //       if (statusResult.statusCode == Globalconstants.CaseRecSuccessResponseCode)
        //       { 
        //         this.logger.log("2. UpdateCaseRecStatus()","Get Response from UpdateCaseRecStatus API for Completed/In-complete Status", [sessionStorage.getItem('caserecid').toString()],  "#UpdateCaseRecStatus() - Get Response from UpdateCaseRecStatus API");                                                      
        //         this.isSuccessAlert = true;
        //         if(this.statusCaseRec == Globalconstants.InCompleteStatus)
        //         {
        //              this.SuccessMessage = Globalconstants.SuccessMessage;
        //         }
        //         else{
        //              this.SuccessMessage = Globalconstants.SubmitMessage;
        //         }

        //        // if(this.statusCaseRec === enumcaseStatus.Complete)
        //        if(this.statusCaseRec === Globalconstants.CompleteStatus) 
        //        {
        //           this.isDisabled = true;
        //           this.isTabDisplay = false;
        //           this.childCaretool.IsCompletedCase(); 
        //           this.childMacra.IsCompletedCase();                  
        //           //console.log('this.statusCaseRec === enumcaseStatus.Complete');
        //         }
                
        //         if(sessionStorage.getItem('mode').toLowerCase()==enumMode.readonly)
        //         {                  
        //           this.isDisabled = true;                  
        //           // Disabled Macra & Caretool controls..
        //           this.childCaretool.IsCompletedCase(); 
        //           this.childMacra.IsCompletedCase();
        //           //console.log('sessionStorage.getItem(mode).toLowerCase()==enumMode.readonly');
        //         }                
        //         window.scroll({
        //           top: 0,
        //           left: 0,
        //           behavior: "smooth"
        //         });

        //       }},
        //       error => {
        //           this.logger.log("saveCaseDetails()","Error raised in tabs.component.ts in saveCaseDetails ", [sessionStorage.getItem('caserecid')],  error.message); 
        //           console.error(error);
        //           this.isErrorAlertAPICall = true; 
        //           this.ErrorMessageAPICall = Globalconstants.ErrorMessageAPICall;                  
        //         }
        //       );

        //       window.scroll({
        //         top: 0,
        //         left: 0,
        //         behavior: "smooth"
        //       });
              
          this.apiService.saveCaseDetailsCaseRecCQF(this.CaseDtl, sessionStorage.getItem('caserecid')).subscribe(
            result => {                         
            
            //result.caseRecStatus = 'failed';
            // console.log('this.apiService.status' + caseStatusByenumKey.get(enumcaseStatus.Complete))
            // console.log('this.apiService.saveCaseDetails()' + result.cqfStatus);
            // console.log('this.apiService.saveCaseDetails()' + result.caseRecStatus);
            // console.log('this.apiService.saveCaseDeta+ils()' + result.cqfStatusDescription);
            // console.log('this.apiService.saveCaseDetails()' + result.caseRecStatusDescription);
              if (result.cqfStatus.toLocaleLowerCase() == Globalconstants.SuccessStatus && result.caseRecStatus.toLocaleLowerCase() == Globalconstants.SuccessStatus)
              {
                setTimeout(() => {
                  this.preventAbuse = false;
                }, 500);                                
                
                this.isSuccessAlert = true;
                if(this.statusCaseRec == Globalconstants.InCompleteStatus)
                {
                     this.SuccessMessage = Globalconstants.SuccessMessage;
                }
                else{
                     this.SuccessMessage = Globalconstants.SubmitMessage;
                }

                if(strStatus === enumcaseStatus.Complete){                  
                  this.isDisabled = true;                  
                  this.isTabDisplay = false;                  
                  
                  // Disabled Macra & Caretool controls..
                  this.childCaretool.IsCompletedCase(); 
                  this.childMacra.IsCompletedCase(); 

                } else{                                    
                  if (sessionStorage.getItem('mode').toLowerCase()==enumMode.readonly)
                  {
                    this.isDisabled = true;                  
                    // Disabled Macra & Caretool controls..
                    this.childCaretool.IsCompletedCase(); 
                    this.childMacra.IsCompletedCase();
                  }
                }
                //scroll to top of screen
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth"
                });
                
              }
              else if(result.cqfStatus.toLocaleLowerCase() == Globalconstants.SuccessStatus && result.caseRecStatus == Globalconstants.FailedStatus)
              {
                setTimeout(() => {
                  this.preventAbuse = false;
                }, 500);
                
                this.CaseRecID = this.childCaseInfo.detailform.get('txtCaseRecID').value;    
                if(strStatus === enumcaseStatus.Complete){                  
                  this.isDisabled = true;                  
                  this.isTabDisplay = false;                  
                  if(this.CaseRecID > 0 && this.CaseRecID!= undefined)
                  {                     
                    this.UpdateCaseRecStatus(this.CaseRecID.toString(),caseStatusByenumKey.get(enumcaseStatus.Complete));                    
                  }
                  else
                  {
                    this.CaseRecID =  this.childCaseInfo.detailform.get('txtCaseRecID').value; 
                    this.UpdateCaseRecStatus(this.CaseRecID.toString(),caseStatusByenumKey.get(enumcaseStatus.Complete));
                    //this.logger.log("saveCaseDetails()","Error raised in tabs.component.ts in UpdateCaseRecStatus Response ", [this.CaseRecID.toString()],  "#Caserec Id cleared from Session. But updating status using textbox caserecID.");                   
                  }
                  // Disabled Macra & Caretool controls..
                  this.childCaretool.IsCompletedCase(); 
                  this.childMacra.IsCompletedCase(); 

                } else{                  
                    if(this.CaseRecID > 0 && this.CaseRecID!= undefined)
                    {
                      //this.logger.log("saveCaseDetails()","Get Response from Mulesoft API for In-Completed Status. Before calling UpdateCaseRecStatus method", [this.CaseRecID.toString()],  "#Before Calling UpdateCaseRecStatus method.");                                                          
                      this.UpdateCaseRecStatus(this.CaseRecID.toString(),caseStatusByenumKey.get(enumcaseStatus.Incomplete));
                      //this.logger.log("saveCaseDetails()","Get Response from Mulesoft API for In-Completed Status. After calling UpdateCaseRecStatus method", [this.CaseRecID.toString()],  "#Before Calling UpdateCaseRecStatus method.");                                                          
                    }
                    else
                    {  
                      this.CaseRecID =  this.childCaseInfo.detailform.get('txtCaseRecID').value; 
                      this.UpdateCaseRecStatus(this.CaseRecID.toString(),caseStatusByenumKey.get(enumcaseStatus.Incomplete));
                      //this.logger.log("saveCaseDetails()","Error raised in tabs.component.ts in UpdateCaseRecStatus Response ", [this.CaseRecID.toString()],  "#Caserec Id cleared from Session. But updating status using textbox caserecID.");                   
                    }
                    if (sessionStorage.getItem('mode').toLowerCase()==enumMode.readonly)
                    {
                      this.isDisabled = true;                  
                      // Disabled Macra & Caretool controls..
                      this.childCaretool.IsCompletedCase(); 
                      this.childMacra.IsCompletedCase();
                    }
                }
                //scroll to top of screen
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth"
                });
                // alert('Record Saved Successfully!!');
              }
              else{
                this.isErrorAlert = true;
                this.ErrorMessage = Globalconstants.ErrorMessage; //"Record failed to save!!";
                //scroll to top of screen
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth"
                });
              // alert('Record Not Saved Successfully!!');
              var arrayParams= [this.childCaseInfo.detailform.get('txtCaseRecID').value, strStatus];
              //this.logger.log("saveCaseDetails()","Error raised in tabs.component.ts - saveCaseDetails() ", arrayParams,  "Else condition saveCaseDetails() for this method: " + result.cqfStatusCode);                           
              }         
          },
          error => {
                  this.logger.log("saveCaseDetails()","Error raised in tabs.component.ts in saveCaseDetails ", [sessionStorage.getItem('caserecid')],  error.message); 
                  console.error(error);
                  this.isErrorAlertAPICall = true; 
                  this.ErrorMessageAPICall = Globalconstants.ErrorMessageAPICall;},
        
          );

  }  
  catch(error)  
  { 
    var arrayParams = [strStatus, sessionStorage.getItem('caserecid')];
    this.logger.log("SaveData()","Error raised in tabs.component.ts - SaveData ", arrayParams,  error.message);    
  } 
    

  }

  closeWindowtab(){
    var x=confirm(Globalconstants.ConfirmMessage);
   
   // alert(x);
    if(x) 
    {
            this.isDisabled = true;
            this.isInfoAlert = true;
            this.InfoMessage = Globalconstants.CancelMessage;  
            //this.DisableAllTabs();
            //scroll to top of screen
            window.scroll({
              top: 0,
              left: 0,
              behavior: "smooth"
            });     
    }
  }

  closeWindow()
  {   
    var x=confirm(Globalconstants.CloseMessage);
    // alert(x);
     if(x) 
     {
       sessionStorage.clear(); 
       window.top.close();
     }
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      ".text-danger"
    );

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth"
    });

    fromEvent(window, "scroll")
      .pipe(
        debounceTime(500),
        take(1)
      )
      .subscribe(() => firstInvalidControl.focus());
  }

  private getTopOffset(controlEl: HTMLElement): number {
    //console.log("In Tabs:",controlEl);
    if(controlEl != null) {
      const labelOffset = 50;
      //return window.scrollY - labelOffset;
      return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
    }
  }
   //progress bar on started
   onStarted() {
    //document.getElementById("overlay").classList.add('disableddiv');
    this.startedClass = true;
    this.isBtnDisabled =true;
    setTimeout(() => {
      this.startedClass = false;
    }, 50);
  }
 //progress bar on completed.
  onCompleted() {
    //document.getElementById("overlay").classList.remove('disableddiv');
    this.completedClass = true;
    this.isBtnDisabled =false;
    setTimeout(() => {
      this.completedClass = false;
    }, 50);
  }

  getResult(){
    return this.isBtnDisabled;
  }

    /* Modal pop up methods */
    openModal(template: TemplateRef<any>) {
      this.modalRef = this.modalService.show(template, {class: 'modal-md', ignoreBackdropClick: true, keyboard: false});
    }
  
    confirm(): void {
          this.isDisabled = true;
          this.isInfoAlert = true;
          this.isSuccessAlert = false;
          this.isErrorAlert =  false;
          this.InfoMessage = Globalconstants.CancelMessage;  
          this.isTabDisplay = false;
          //scroll to top of screen
          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
          });     
          this.modalRef.hide();
          sessionStorage.clear(); 
    }
  
    decline(): void {
        //this.message = 'Declined!';
        this.modalRef.hide();
    }

    isMobileDevice(){
      // credit to Timothy Huang for this regex test: 
      // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
          return true
      }
      else{
          return false
      }
  }

}